<template>
  <div>
    <UiOnClickOutside :do="closeDropdownModal">
      <div>
        <!-- Time Picker Label -->
        <div
          v-if="!hideTitle"
          class="text-text-color text-xs font-normal font-roboto flex pb-1 capitalize"
        >
          <span>{{ title }}</span>
          <span v-if="rules.includes('required')" class="text-text-color-danger pl-1">*</span>
        </div>
        <!-- Selected Time  -->
        <div class="relative">
          <Field v-model="currentSelectedTime" :rules="rules" :name="name ? name : title" as="div">
            <div
              :class="[
                isDateTime ? '' : 'border border-primary-grey',
                isErrorAvailable ? 'error-border' : '',
                inputColor,
                disabled ? 'cursor-not-allowed' : 'cursor-pointer',
              ]"
              class="w-full h-11 justify-between px-3.5 flex items-center rounded-md"
              @click.prevent="toggleDropdown"
            >
              <button
                type="text"
                :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'"
                class="h-full w-full flex justify-between items-center text-base focus:outline-none"
              >
                <div class="lg:text-base text-sm text-primary-gray-700">
                  <span v-if="currentSelectedTime">
                    {{ currentSelectedTime }}
                  </span>
                  <span v-else class="text-primary-gray-400 text-sm">Select Time</span>
                </div>
                <div>
                  <icon icon="clock" color="primary-gray-700" height="21" width="20" />
                </div>
              </button>
            </div>
            <div
              ref="errorContainer"
              :class="[!isDateTime ? 'flex justify-end h-5 ' : 'absolute right-1']"
            >
              <ErrorMessage v-slot="{ message }" :name="name ? name : title">
                <small class="error-msg text-text-color-danger text-sm italic">
                  <p>{{ displayError(message) }}</p>
                </small>
              </ErrorMessage>
            </div>
            <input v-show="false" v-model="currentSelectedTime" />
          </Field>
          <!-- Dropdown -->
          <div
            v-if="showDropdown"
            class="timepicker-container shadow-lg shadow-primary-gray-600 border-primary-gray-200 border w-[300px] sm:w-[328px] bg-white rounded-lg absolute top-[55px] z-50 after:absolute after:-top-1.5 after:w-3.5 after:h-[14px] after-content-[''] after:bg-white after:rounded-[4px] after:border after:border-primary-gray-200 after:-rotate-45"
            :class="customClasses"
          >
            <div class="flex justify-center items-center gap-3 mt-4.5 mx-6 mb-3">
              <div
                class="h-10 px-3.5 w-[194px] border-primary-gray-300 border rounded-lg flex justify-around items-center"
              >
                <div class="font-roboto font-semibold text-primary-gray-700 text-base">
                  {{ currentSelectedTime }}
                </div>

                <div class="text-primary-gray-900 text-sm font-normal">GMT{{ timeZone }}</div>
              </div>
              <div
                class="h-10 px-3.5 w-[74px] border-primary-gray-300 cursor-pointer border font-semibold text-primary-gray-700 text-sm rounded-lg flex justify-around items-center"
                @click="resetCurrentTime()"
              >
                Now
              </div>
            </div>

            <!-- Time -->
            <div class="border-b border-primary-gray-200">
              <div class="h-[311px]">
                <div class="flex items-center justify-center h-[inherit] gap-5">
                  <div class="relative flex h-[inherit] gap-[10px] text-sm font-medium">
                    <div
                      class="absolute top-[51.9%] left-0 w-full h-12 border-y border-primary-purple-100 selected-time"
                    ></div>
                    <div class="relative flex h-[inherit] gap-[10px]">
                      <div
                        class="selected-hour rounded-lg h-10 w-full left-0 top-[51.5%] absolute bg-primary-purple-100"
                      ></div>
                      <div
                        ref="hours"
                        class="hours py-[133px] overflow-y-auto z-10"
                        :tabindex="tabIndex"
                        @scroll="handleScroll('hours')"
                        @mouseover="setActivePicker('hours')"
                      >
                        <div
                          v-for="hour in hours"
                          :key="hour"
                          class="flex items-center justify-center w-10 h-[44.5px]"
                        >
                          <span class="cursor-pointer" @click="setHour(hour)">
                            {{ hour }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="relative flex h-[inherit] gap-[10px]">
                      <div
                        class="selected-minute rounded-lg h-10 w-full left-0 top-[51.5%] absolute bg-primary-purple-100"
                      ></div>
                      <div
                        ref="minutes"
                        class="minutes py-[133px] overflow-y-auto z-10"
                        :tabindex="tabIndex"
                        @scroll="handleScroll('minutes')"
                        @mouseover="setActivePicker('minutes')"
                      >
                        <div
                          v-for="minute in minutes"
                          :key="minute"
                          class="flex items-center justify-center w-10 h-[44.5px]"
                        >
                          <span class="cursor-pointer" @click="setMinute(minute)">
                            {{ minute }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="meridiem flex items-center overflow-y-auto justify-center border border-primary-gray-200 gap-[3px] text-primary-gray-700 rounded-lg"
                  >
                    <div
                      class="w-10 font-semibold text-xs h-8 flex justify-center cursor-pointer items-center rounded-md m-[3px]"
                      :class="{
                        'bg-primary-purple-100 text-primary-purple-600':
                          timeMeridiem === DATE_TIME.AM,
                      }"
                      @click="setTimeMeridiem(DATE_TIME.AM)"
                    >
                      {{ DATE_TIME.AM }}
                    </div>
                    <div
                      class="w-10 h-8 flex justify-center items-center cursor-pointer font-semibold text-xs rounded-md m-[3px]"
                      :class="{
                        'bg-primary-purple-100 text-primary-purple-600':
                          timeMeridiem === DATE_TIME.PM,
                      }"
                      @click="setTimeMeridiem(DATE_TIME.PM)"
                    >
                      {{ DATE_TIME.PM }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Buttons -->
            <div class="flex items-center justify-center gap-3 md:p-4 p-2.5">
              <button
                class="h-10 w-full focus:outline-none focus:shadow-md text-sm font-semibold text-white font-roboto flex justify-center items-center primary-button-outline modal-buttons"
                @click="closeDropdownModal"
              >
                Cancel
              </button>
              <button
                class="h-10 w-full focus:outline-none focus:shadow-md text-sm font-semibold text-white font-roboto flex justify-center items-center primary-button modal-buttons"
                @click="setCurrentSelectedTime()"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </UiOnClickOutside>
  </div>
</template>

<script>
import icon from '@components/icons/icon.vue'
import {
  arrayRange,
  addPadInString,
  convertedSelectedTime,
  translatedError,
} from '@src/utils/generalUtil.js'
import moment from 'moment'
import UiOnClickOutside from '@components/UiElements/UiOnClickOutside.vue'
import dropdownMixin from '@src/mixins/dropdown-mixins'
import DATE_TIME from '@src/constants/date-time-constants.js'
import { mapGetters } from 'vuex'
import {
  currentTimeWithZone,
  getTimeZoneList,
  formatDateFromLocalToUTC,
} from '@src/utils/moment.util.js'
import validationMixin from '@src/mixins/components/validation-mixin.js'

import { Field, configure, ErrorMessage } from 'vee-validate'
import { validationConfiguration } from '@src/vee-validate/index.js'
configure(validationConfiguration)
export default {
  components: {
    Field,
    ErrorMessage,
    icon,
    UiOnClickOutside,
  },
  mixins: [dropdownMixin, validationMixin],
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    hideDefaultTime: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Time',
    },
    customClasses: {
      type: String,
      default: 'after:right-7 -right-0.5',
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    isDateTime: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: '',
    },
    inputColor: { type: String, default: '' },
    rules: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      DATE_TIME,
      isErrorAvailable: false,
      currentSelectedHour: null,
      currentSelectedMinute: null,
      currentHour: null,
      keyScrollAmount: 44.5,
      selectedTimePosition: 155.5,
      defaultTime: '',
      currentMinute: null,
      selectedHours: null,
      selectedMinutes: null,
      showDropdown: false,
      isChrome: false,
      tabIndex: 0,
      meridiem: false,
      activePicker: 'hours',
      timeMeridiem: 'AM',
      currentSelectedTime: '',
      timeZone: null,
      selectedIndex: 1,
    }
  },
  computed: {
    ...mapGetters('layout', ['campusTimeZone']),
    minutes() {
      const min = Array.from({ length: 60 }, (_, i) => i).map((num) =>
        num < 10 ? `0${num}` : `${num}`,
      )

      return min
    },
    hours() {
      return Array.from({ length: 12 }, (_, i) => i + 1).map((num) =>
        num < 10 ? `0${num}` : `${num}`,
      )
    },
  },
  watch: {
    modelValue: {
      handler() {
        this.errorHandler()
      },
    },
    rules: {
      handler() {
        this.errorHandler()
      },
    },
  },
  /**
   * Call Current time function
   */
  created() {
    this.setCurrentTime()

    if (this.modelValue) {
      this.defaultTime = this.modelValue
      this.setDefaultTime()
    }
  },
  mounted() {
    this.isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
    window.addEventListener('keydown', this.handleKeyDown)
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
  },
  methods: {
    convertedSelectedTime,
    setCurrentTime() {
      let currentTimeZone = getTimeZoneList().find(
        (timeZone) => timeZone.timeDifference === this.campusTimeZone,
      )
      let date = currentTimeWithZone(currentTimeZone?.zone)
      // Set Current time
      let currentHours = formatDateFromLocalToUTC(date, DATE_TIME.HOUR_24)
      this.meridiem = currentHours >= 12
      currentHours = moment(currentHours, [DATE_TIME.HOUR_24]).format(DATE_TIME.HOUR_12)

      let currentMinutes = formatDateFromLocalToUTC(date, DATE_TIME.MINUTES)

      // Set Selected time
      this.selectedHours = currentHours
      this.selectedMinutes = currentMinutes
      this.setTime()
    },
    handleKeyDown(event) {
      const key = event.key
      switch (this.activePicker) {
        case 'hours':
          this.setHoursOnKeysAction(key)
          break
        case 'minutes':
          this.setMinutesOnKeysAction(key)
          break
      }
    },
    setMinutesOnKeysAction(key) {
      const minutesContainer = this.$refs['minutes']
      if (this.showDropdown) event.preventDefault()
      if (key === 'ArrowDown' && this.currentSelectedHour !== '59') {
        minutesContainer.scrollTop += this.keyScrollAmount
      } else if (key === 'ArrowUp' && this.currentSelectedHour !== '00') {
        minutesContainer.scrollTop -= this.keyScrollAmount
      }
    },
    setHoursOnKeysAction(key) {
      const hoursContainer = this.$refs['hours']
      if (this.showDropdown) event.preventDefault()
      if (key === 'ArrowDown' && this.currentSelectedMinute !== '12') {
        hoursContainer.scrollTop += this.keyScrollAmount
      } else if (key === 'ArrowUp' && this.currentSelectedMinute !== '01') {
        hoursContainer.scrollTop -= this.keyScrollAmount
      }
    },
    setActivePicker(picker) {
      this.activePicker = picker
    },
    getDivFromPosition(container, position) {
      let scrollBehaviour = this.isChrome ? 'auto' : 'smooth'
      const containerDimensions = container.getBoundingClientRect()
      for (let i = 0; i < container.children.length; i++) {
        const childDimensions = container.children[i].getBoundingClientRect()
        const childTopRelativeToContainer = childDimensions.top - containerDimensions.top
        const childBottomRelativeToContainer = childDimensions.bottom - containerDimensions.top
        if (position >= childTopRelativeToContainer && position <= childBottomRelativeToContainer) {
          let element = container.children[i]
          if (element) {
            element.scrollIntoView({ block: 'center', inline: 'center' })
          }
          container.children[i].style.color = 'var(--primary-purple-600)'
          if (container.children[i - 1])
            container.children[i - 1].style.color = 'var(--primary-gray-600)'
          if (container.children[i + 1])
            container.children[i + 1].style.color = 'var(--primary-gray-600)'
          if (container.children[i - 2])
            container.children[i - 2].style.color = 'var(--primary-gray-500)'
          if (container.children[i + 2])
            container.children[i + 2].style.color = 'var(--primary-gray-500)'
          if (container.children[i - 3])
            container.children[i - 3].style.color = 'var(--primary-gray-400)'
          if (container.children[i + 3])
            container.children[i + 3].style.color = 'var(--primary-gray-400)'
          return i
        }
      }
      return null
    },
    setHour(value) {
      const hoursContainer = this.$refs['hours']
      for (let i = 0; i < this.hours.length; i++) {
        if (this.hours[i] === value) {
          hoursContainer.children[i].scrollIntoView({ behavior: 'instant', block: 'center' })
          this.handleScroll('hours')
        }
      }
    },
    setMinute(value) {
      const minutesContainer = this.$refs['minutes']
      for (let i = 0; i < this.minutes.length; i++) {
        if (this.minutes[i] === value) {
          minutesContainer.children[i].scrollIntoView({ behavior: 'instant', block: 'center' })
          this.handleScroll('minutes')
        }
      }
    },
    handleScroll(type) {
      const container = this.$refs[type]
      this.selectedIndex = this.getDivFromPosition(container, this.selectedTimePosition)
      if (type === 'hours') {
        this.currentSelectedHour = addPadInString(
          this.hours[this.selectedIndex % this.hours.length],
        )
      } else if (type === 'minutes') {
        this.currentSelectedMinute = addPadInString(
          this.minutes[this.selectedIndex % this.minutes.length],
        )
      }
      this.setSelectedTime()
    },
    setTimeMeridiem(meridiem) {
      this.timeMeridiem = meridiem
      this.meridiem = !this.meridiem
      this.setSelectedTime()
    },
    getTimeMeridiem() {
      this.timeMeridiem = !this.meridiem ? DATE_TIME.AM : DATE_TIME.PM
    },
    setSelectedTime() {
      this.currentSelectedTime = `${this.currentSelectedHour}:${this.currentSelectedMinute} ${this.timeMeridiem}`
    },
    translatedError,
    /**
     * Toggle TimePicker dropdown
     */
    toggleDropdown() {
      if (!this.disabled) {
        if (this.showDropdown === false) {
          setTimeout(() => {
            this.setHour(this.currentSelectedHour)
            this.setMinute(this.currentSelectedMinute)
          })
        }
        this.showDropdown = !this.showDropdown
      }
    },
    resetCurrentTime() {
      this.setCurrentTime()
      this.setHour(this.currentSelectedHour)
      this.setMinute(this.currentSelectedMinute)
    },
    setCurrentSelectedTime() {
      this.getTimeMeridiem()
      if (this.currentSelectedHour && this.currentSelectedMinute) {
        this.setSelectedTime()
        const convertedTime = convertedSelectedTime(this.currentSelectedTime)
        let time = convertedTime.concat(this.timeZone)
        this.$emit('update:modelValue', time)
        this.defaultTime = time
      }
      this.showDropdown = false
    },
    closeDropdownModal() {
      this.setCurrentTime()
      if (this.defaultTime) this.setDefaultTime()
      this.showDropdown = false
    },
    /**
     * Set current time
     * Convert current time into 24-hours format
     * @return converted time
     */
    setDefaultTime() {
      this.selectedHours = this.getHourFromTime(this.defaultTime)
      this.meridiem = this.selectedHours >= 12
      this.selectedHours = moment(this.selectedHours, [DATE_TIME.HOUR_24]).format(DATE_TIME.HOUR_12)
      this.selectedMinutes = this.getMinFromTime(this.defaultTime)
      this.setTime()
    },
    getMinFromTime(time) {
      return time.split(':')[1]
    },
    getHourFromTime(time) {
      return time.split(':')[0]
    },
    setTime() {
      this.currentHour = addPadInString(this.selectedHours)
      this.currentMinute = addPadInString(this.selectedMinutes)
      this.currentSelectedHour = this.currentHour
      this.currentSelectedMinute = this.currentMinute
      this.getTimeMeridiem()
      if (!this.hideDefaultTime) {
        this.setSelectedTime()
      }
      const convertedTime = convertedSelectedTime(this.currentSelectedTime)
      this.timeZone = this.campusTimeZone
      this.$emit('update:modelValue', convertedTime.concat(this.timeZone))
    },

    /**
     * Reset slected time when meridiem change
     */
  },
}
</script>

<style lang="scss" scoped>
*:focus {
  outline: none;
}
.selected-time {
  pointer-events: none;
  transform: translateY(-65%);
}
.selected-hour,
.selected-minute {
  transform: translateY(-65%);
}
.hours,
.minutes {
  flex: 1;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.hours::-webkit-scrollbar,
.minutes::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge */
}

.meridiem {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.meridiem::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge */
}
.timepicker-container {
  &::after {
    clip-path: polygon(0% -10%, 100% 0%, 115% 100%);
  }
}
</style>
