<template>
  <div>
    <TitleWrapper
      :filter-option="true"
      title="SCHED_MGR"
      tooltip-title="SCHED_MGR"
      :display-breadcrumb="true"
    >
      <template v-slot:filterItems>
        <InputFieldWrapper layout-mode="true">
          <UiSingleSelect
            v-model="routineFilter.teacher"
            title="Teacher Name"
            :options="routineFilterData.teachers"
            label="full_name"
            class="flex-1"
            :class="$style.widthItems"
            :reduce="(option) => option.id"
            @change="loadAllSubjects"
          ></UiSingleSelect>
          <UiSingleSelect
            v-model="routineFilter.room"
            title="Room"
            :options="routineFilterData.rooms"
            label="title"
            reduce="id"
            class="flex-1"
            :class="$style.widthItems"
          ></UiSingleSelect>
          <UiSingleSelect
            v-model="routineFilter.subject"
            title="Subject"
            :options="routineFilterData.subjects"
            reduce="id"
            label="title"
            class="flex-1"
            :class="$style.widthItems"
          ></UiSingleSelect>
        </InputFieldWrapper>
      </template>
    </TitleWrapper>
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'
import { mapActions } from 'vuex'
export default {
  components: {
    TitleWrapper,
    UiSingleSelect,
    InputFieldWrapper,
  },
  data() {
    return {
      routineFilter: {
        teacher: '',
        subject: '',
        room: '',
      },
      routineFilterData: {
        teachers: [],
        subjects: [],
        rooms: [],
      },
    }
  },
  methods: {
    loadFilterData() {
      const query = {
        section_id: this.classId,
      }

      this.loadTeachers(query).then((resp) => {
        this.routineFilterData.teachers = resp.data.map((teacher) => ({
          ...teacher,
          full_name: `${teacher.first_name} ${teacher.last_name}`,
        }))
      })
      this.loadAllSubjects()
      this.loadAllRooms()
    },

    loadAllRooms() {
      const query = {
        section_id: this.classId,
      }

      this.getRoomsForClass(query).then((resp) => {
        this.routineFilterData.rooms = resp.data
      })
    },
    loadAllSubjects() {
      const query = {
        section_id: this.classId,
      }

      this.loadSubjects(query).then((resp) => {
        this.routineFilterData.subjects = resp.data
      })
    },

    ...mapActions('routine', ['loadTeachers', 'getRoomsForClass', 'loadSubjects', 'loadRooms']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
