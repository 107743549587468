<template>
  <Filters />
  <Loader v-if="isLoading" class="mt-20 mb-40" :content="true" />
  <Schedule
    v-else
    :key="forceRerender"
    class="mt-5"
    :theads="['monday', 'sunday']"
    :table-time="[timeRange[0] || DATE_TIME.dayStartTime, timeRange[1] || DATE_TIME.dayEndTime]"
    :events="events"
    @viewEvent="viewEvent"
    @editEvent="editEvent"
    @deleteEvent="toggleConfirmationModal"
  />
  <UIModalContainer
    :modal-show="showModal"
    footer-classes="flex justify-end"
    @handleClick="handleClick"
  >
    <template v-slot:header>
      <span v-if="routineData.id" v-i18n="rightBar">EDIT_TIME_TABLE_MGR</span>
      <span v-else v-i18n="rightBar">ADD_TIME_TABLE_MDR</span>
    </template>
    <template v-slot>
      <div>
        <ValidationObserver ref="createUpdateRoutine" @submit="addNewRoutine">
          <InputFieldWrapper>
            <UiSingleSelect
              v-model="routineData.subject_id"
              title="SUBJECT"
              :options="routineInfo.subjects"
              label="title"
              rules="required"
              reduce="id"
              @change="getTeachersForSubject(routineData.subject_id)"
            />
            <UiSingleSelect
              v-model="routineData.teacher_id"
              :disabled="disabled"
              title="Teacher"
              :options="routineInfo.teachers"
              label="full_name"
              reduce="id"
              :image="true"
            />
          </InputFieldWrapper>

          <InputFieldWrapper>
            <UiSingleSelect
              v-model="routineData.room_id"
              title="Room"
              :options="routineInfo.rooms"
              label="title"
              reduce="id"
            />
            <UiSingleSelect
              v-model="routineData.day"
              title="DAY"
              :options="routineInfo.days"
              label="DAY"
              rules="required"
            />
          </InputFieldWrapper>

          <InputFieldWrapper class="flex-1">
            <UiTimePicker
              v-model="routineData.start_time"
              :title="$t(`title.START_TIME`)"
              type="time"
              class="flex-1"
              rules="required"
            />
            <UiTimePicker
              v-model="routineData.end_time"
              :title="$t(`title.END_TIME`)"
              type="time"
              class="flex-1"
              rules="required"
            />
          </InputFieldWrapper>

          <button ref="submit" class="hidden">Submit</button>
        </ValidationObserver>
      </div>
    </template>
    <template v-slot:footer>
      <div class="flex gap-3 rtl:gap-x-4">
        <UIButton :disabled="loadingAddNewRoutine" @click="handleClick('close')">Cancel</UIButton>
        <UIButton button="primary" @click="$refs.submit.click()">
          <template v-if="loadingAddNewRoutine">
            <Loader></Loader>
          </template>
          <span v-else-if="routineData.id">Update</span>
          <span v-else>Save</span>
        </UIButton>
      </div>
    </template>
  </UIModalContainer>

  <UIModalContainer
    footer-classes="flex justify-end"
    :modal-show="routinesDetailModal"
    :modal-width="40"
    @handleClick="handleRoutinesDetailClick"
  >
    <template v-slot:header>
      <span v-i18n="routine">PER_MGR_DETAILS</span>
    </template>
    <template v-slot>
      <div v-for="event in detailsEventsArr" :key="event.id" class="pb-8">
        <div class="flex flex-col gap-5 sm:flex-row">
          <span class="text-2xl text-label-text font-bold">{{ event.title }}</span>
          <span class="subjectColor"></span>
        </div>
        <div class="flex flex-1 justify-between">
          <div class="py-3 flex flex-1 items-center">
            <Icon icon="calender" color="primary-green" height="21" width="18" />
            <span class="px-2 md:px-3 text-xs md:text-base text-primary-grey-light capitalize">
              {{ event.day }}
            </span>
          </div>
          <div class="py-3 flex flex-1 clock-icon items-center">
            <Icon icon="clock" color="primary-green" height="21" width="18" />
            <span class="px-2 md:px-3 text-xs md:text-base text-primary-grey-light">
              {{ localTime(event.startTime) }} - {{ localTime(event.endTime) }}
            </span>
          </div>
        </div>

        <div class="flex flex-1 justify-between">
          <div class="flex flex-1 py-3">
            <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
              <span v-i18n="routine">Room</span>
              :
              <span class="md:px-3 text-primary-grey-light font-normal">
                {{ event.room }}
              </span>
            </div>
          </div>
          <div class="flex flex-1 py-3">
            <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
              <span v-i18n="routine">Subject</span>
              :
              <span class="md:px-3 text-primary-grey-light font-normal">
                {{ event.subject.title }}
              </span>
            </div>
          </div>
        </div>
        <div class="flex flex-1 justify-between">
          <div class="flex flex-1 py-3">
            <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
              <span v-i18n="routine">Teacher</span>
              :
              <span class="md:px-3 text-primary-grey-light font-normal">
                {{ event.teacher }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </UIModalContainer>
  <UIConfirmationModal
    heading="DELETE_TIME_TABLE_AUTOMATOR"
    message="Are you sure you want to delete this "
    button-text="Delete"
    :modal="confirmation"
    :name="currentEvent?.title"
    remaining-message=" ?"
    @cancel="toggleConfirmationModal"
    @confirm="deleteEvent"
  />
</template>

<script>
import moment from 'moment'
import { isEmpty } from 'lodash'
import Filters from '@views/time-table/time-table-automator/PeriodManagerFilters.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import UiTimePicker from '@/src/components/UiElements/UiTimePicker.vue'
import UIModalContainer from '@components/UiElements/UiModalContainer.vue'
import Schedule from '@components/UiElements/Scheduler/Schedule.vue'
import { mapActions, mapState } from 'vuex'
import Icon from '@components/icons/icon.vue'
import { getSubjectEvent, getTimeRange } from '@utils/timeTableUtils'
import DATE_TIME from '@src/constants/date-time-constants.js'
import { fullName } from '@src/utils/settings/tenant-user.util.js'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import timeMixin from '@src/mixins/timeMixin'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import { Form as ValidationObserver } from 'vee-validate'
import { objectDeepCopy } from '@utils/generalUtil'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  name: 'ScheduleManager',
  components: {
    ValidationObserver,
    Loader,
    UiSingleSelect,
    Schedule,
    Icon,
    UiTimePicker,
    UIModalContainer,
    Filters,
    UIConfirmationModal,
    UIButton,
    InputFieldWrapper,
  },
  mixins: [timeMixin],
  data() {
    return this.initialState()
  },
  page: {
    title: 'Schedule Manager',
    meta: [
      {
        name: 'description',
        content: 'Manage  schedule of class ',
      },
    ],
  },
  computed: {
    ...mapState({
      activeRole: (state) => state.layout.activeRole,
      currentUser: (state) => state.auth.currentUser,
      showModal: (state) => state.layout.showModal,
      campusId: (state) => state.layout.currentCampusScope.id,
      classId: (state) => state.layout.currentSectionScope.id,
    }),
    routine() {
      return 'routine'
    },
    title() {
      return 'title'
    },
  },
  watch: {
    classId: {
      deep: true,
      handler() {
        if (this.classId) this.loadRightBarStats()
        else this.resetComponent()
      },
    },
    events: {
      deep: true,
      immediate: true,
      handler() {
        const temp = getTimeRange(this.events)
        this.timeRange[0] = temp[0]
        this.timeRange[1] = temp[1]
      },
    },
    showModal: {
      immediate: true,
      deep: true,
      handler: 'initCreateRoutine',
    },
  },
  created() {
    this.setRightbarContent({
      header: {
        title: 'SCHED_MGR',
        buttons: [
          {
            title: 'ADD_TIME_TABLE_MDR',
            classes: ['primary-button-right'],
            action: { name: 'layout/setShowModal', payload: {} },
          },
        ],
      },
      stats: [
        {
          title: this.$t('routine.Subject'),
          categories: [
            { text: 'Total Subject', value: '--' },
            { text: `Todays Subject`, value: '--' },
          ],
        },
      ],
    })
    const startDate = new Date()
    const endDate = new Date()

    endDate.setDate(endDate.getDate() + 7)
    this.dateRange.startDate = startDate
    this.dateRange.endDate = endDate
    if (this.classId) this.loadRightBarStats()
  },
  beforeUnmount() {
    this.viewEvent({})
  },
  methods: {
    initialState() {
      return {
        currentEventTitle: '',
        DATE_TIME,
        disabled: false,
        currentEvent: null,
        confirmation: false,
        attendanceTranslation: 'attendanceTranslation',
        rightBar: 'rightBar',
        isEmpty,
        isLoading: false,
        forceRerender: 0,
        errors: {},
        loadingAddNewRoutine: false,
        dateRange: {
          startDate: null,
          endDate: null,
        },
        timeRange: ['0:00 am', '0:00 am'],
        events: [],
        respDataObjDump: null,
        routinesDetailModal: false,
        detailsEventsArr: [],
        routineInfo: {
          subjects: [],
          teachers: [],
          rooms: [],
          days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
        },

        routineData: {
          day: '',
          section_id: this.classId,
          subject_id: null,
          teacher_id: null,
          room_id: null,
          color: null,
          start_time: null,
          end_time: null,
        },
        filters: {
          filterBy: 'week',
        },
      }
    },
    resetComponent() {
      Object.assign(this.$data, this.initialState())
      this.setRightbarContent({
        header: {
          title: 'SCHED_MGR',
          buttons: [
            {
              title: 'ADD_TIME_TABLE_MDR',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal', payload: {} },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [
              { text: 'Total Subject', value: '--' },
              { text: `Todays Subject`, value: '--' },
            ],
          },
        ],
      })
    },
    applyFilter() {
      const data = {
        section_id: this.classId,
      }
      this.filterRoutine(data).then((resp) => {
        this.applyRoutineFilter(resp)
        this.forceRerender++
      })
    },
    resetFilter() {
      const data = {
        section_id: this.classId,
      }
      this.filterRoutine(data).then((resp) => {
        this.applyRoutineFilter(resp)
      })
    },
    applyRoutineFilter(resp) {
      let response = objectDeepCopy(resp)
      this.events = []
      const totalSubject = response?.data?.right_bar?.total_subjects
      const todaysPeriods = response?.data?.right_bar?.today_periods
      const totalPeriodManagers = response?.data?.right_bar?.subjects_with_periods
      response.data.records.forEach((item) => {
        this.events.push(getSubjectEvent(item))
      })
      this.respDataObjDump = response.data.records
      this.setRightbarContent({
        header: {
          title: 'SCHED_MGR',
          buttons: [
            {
              title: 'ADD_TIME_TABLE_MDR',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal', payload: {} },
            },
          ],
        },
        stats: [
          {
            title: this.$t('routine.Subject'),
            categories: [
              {
                text: 'Total Subject',
                value: totalSubject,
                tooltipDescription: 'Total no of Subject',
              },
              {
                text: 'TOTAL_P_MANAGER',
                value: totalPeriodManagers,
                tooltipDescription: 'Total Period Manager',
              },
              {
                text: 'TODAY_PERIODS',
                value: todaysPeriods,
                tooltipDescription: 'Period held today',
              },
            ],
          },
        ],
      })
    },

    handleClick(eventType) {
      if (eventType === 'close') this.closeAddNewRoutineModal()
    },

    handleRoutinesDetailClick(eventType) {
      if (eventType === 'close') this.routinesDetailModal = false
    },

    closeAddNewRoutineModal() {
      this.$store.dispatch('layout/setShowModal', false)
      this.clearNewRoutineData()
    },

    clearNewRoutineData() {
      this.routineData.day = ''
      this.routineData.section_id = this.classId
      this.routineData.subject_id = null
      this.routineData.teacher_id = null
      this.routineData.room_id = null
      this.routineData.color = null
      this.routineData.start_time = null
      this.routineData.end_time = null

      if (this.routineData.id) {
        delete this.routineData.id
      }
    },

    viewEvent(eventId) {
      let detailsEventsArr = []
      const event = this.events.find((event) => event.id === eventId)
      if (event) {
        detailsEventsArr.push(event)

        if (event?.isOverlapped) {
          const { overlappedIds } = event || {}
          let allOverlappedEvents = null
          allOverlappedEvents = overlappedIds.map((id) => {
            return getSubjectEvent(this.respDataObjDump.find((obj) => obj.id === id))
          })

          detailsEventsArr = [...detailsEventsArr, ...allOverlappedEvents]
        }
        this.detailsEventsArr = detailsEventsArr
        this.routinesDetailModal = true
      }
    },

    editEvent(event) {
      const subjects = this.getSubjectsRoomsForClass(event.section_id)
      const teachers = this.getTeachersForSubject(event.subject.subjectId)

      Promise.all([subjects, teachers])
        .then(() => {
          this.routineData.id = event.id
          this.routineData.section_id = event.section_id
          this.routineData.subject_id = event.subject.subjectId
          this.routineData.room_id = event.room_id
          this.routineData.teacher_id = event.teacher_id
          this.routineData.day = event.day.charAt(0).toUpperCase() + event.day.slice(1)
          this.routineData.color = event.color
          this.routineData.start_time = event.startTime.slice(0, -3)
          this.routineData.end_time = event.endTime.slice(0, -3)
        })
        .then(() => {
          this.$store.dispatch('layout/setShowModal', true)
        })
    },
    toggleConfirmationModal(event) {
      this.currentEvent = event
      this.confirmation = !this.confirmation
      this.$store.dispatch('routine/setShowRoutineDeleteModal', this.confirmation)
      if (!this.confirmation) this.currentEvent = null
    },
    deleteEvent() {
      const { id } = this.currentEvent

      this.deactivateRoutine(id).then(() => {
        this.$store.commit('toast/NEW', {
          type: 'success',
          message: this.$t(`toast.Routine Deactivated!`),
        })
        this.applyFilter()
        this.toggleConfirmationModal()
      })

      this.viewEvent({})
    },

    initCreateRoutine(status) {
      if (status) {
        this.getSubjectsRoomsForClass(this.classId)
      }
      if (this.activeRole === TENANT_ROLES.SECTION_TEACHER) {
        this.disabled = true
        let user = objectDeepCopy(this.currentUser)
        this.routineInfo.teachers.push(user)
        fullName(user)
        this.routineData.teacher_id = user?.id
      }
    },

    getSubjectsRoomsForClass(classId) {
      this.errors.class = []
      let teacherPayload
      if (this.activeRole === TENANT_ROLES.SECTION_TEACHER) {
        teacherPayload = {
          ...buildWhereQuery(FILTER_KEYS.IN, 'assigned_teachers_ids', [this.currentUser?.id]),
        }
      }
      return new Promise((resolve, reject) => {
        const query = {
          $where: {
            ...teacherPayload,
            ...buildWhereQuery(FILTER_KEYS.EQUAL, 'section_id', classId),
          },
        }
        const subjects = this.classSubjects(query).then((response) => {
          this.routineInfo.subjects = objectDeepCopy(response.data.records)
        })
        const rooms = this.getRoomsForClass(query)
        Promise.all([subjects, rooms]).then((values) => {
          this.routineInfo.rooms = values[1].data
          resolve()
        })
      })
    },

    getTeachersForSubject(subject) {
      if (this.activeRole === TENANT_ROLES.SECTION_TEACHER) return
      this.routineData.teacher_id = null

      this.$nextTick(() => {
        this.$refs.createUpdateRoutine.reset()
      })
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'subject_id', subject),
        },
      }
      this.errors.subject = []
      return new Promise((resolve, reject) => {
        this.getSectionSubjectTeacher(payload).then((response) => {
          this.routineInfo.teachers = objectDeepCopy(response.data.records)
          this.routineInfo.teachers.forEach((teacher) => {
            fullName(teacher)
          })
          resolve()
        })
      })
    },

    addNewRoutine() {
      if (!this.loadingAddNewRoutine) {
        this.loadingAddNewRoutine = true
        const routineDate = moment()

        const data = {
          routine: {
            day: this.routineData.day.toLowerCase(),
            started_at_time: this.routineData.start_time,
            ended_at_time: this.routineData.end_time,
            room_id: this.routineData.room_id,
            subject_id: this.routineData.subject_id,
            section_id: this.classId,
            teacher_id: this.routineData.teacher_id,
            is_active: true,
            date: routineDate.format('YYYY-MM-DD'),
            to_date: routineDate.add(5, 'days').format('YYYY-MM-DD'),
          },
          id: this.routineData.id,
        }

        this.addRoutine(data)
          .then((resp) => {
            this.applyFilter()
            this.closeAddNewRoutineModal()
          })
          .finally(() => {
            this.loadingAddNewRoutine = false
          })
      }
    },

    loadRightBarStats() {
      const query = {
        section_id: this.classId,
      }
      this.isLoading = true
      this.filterRoutine(query)
        .then((resp) => {
          this.applyRoutineFilter(resp)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('period', ['getSectionSubjectTeacher']),
    ...mapActions('routine', [
      'getRoomsForClass',
      'addRoutine',
      'filterRoutine',
      'deleteRoutine',
      'deactivateRoutine',
    ]),
    ...mapActions('subjects', ['classSubjects']),
  },
}
</script>

<style lang="scss">
.vs__dropdown-toggle {
  height: inherit;
}

.vs--single.vs--open .vs__selected {
  position: relative !important;
}

.vs__dropdown-menu {
  right: 0 !important;
  left: auto !important;
}
</style>
